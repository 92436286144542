import axios from "axios";
export const host = process.env.REACT_APP_BACKEND_URL;

export const getSocketUrl = async () => {
  try {
    const { data } = await axios.get(`${host}/getSocketURL `);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findCurrencyConvertionRate = async (baseValue) => {
  try {
    const { data } = await axios.get(`${host}/getConversionValue/${baseValue}`);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const AllCommodities = async (id) => {
  try {
    const { data } = await axios.get(`${host}/allCommodities/${id}`, {});
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getLoginDevices = async (id) => {
  try {
    const { data } = await axios.get(`${host}/getLoginDevices/${id}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const adminRemoveDevice = async (value) => {
  try {
    const { data } = await axios.put(`${host}/adminRemoveDevice`, value);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const addDisplayingId = async (value) => {
  try {
    const { data } = await axios.put(`${host}/addDisplayingId`, value);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const commodityDragAndDrop = async (token, values) => {
  try {
    const { data } = await axios.put(`${host}/commodityDragAndDrop`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
export const DeleteCommodity = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteCommodity/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const addCommodity = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/addCommodity`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editCommodity = async (token, datas, id) => {
  try {
    const { data } = await axios.put(`${host}/editCommodity/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addAdminBankDetails = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/addBankDetails`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const AllbankDetails = async (adminId, token) => {
  try {
    const data = await axios.get(`${host}/getAllBankDetails/${adminId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const allUsers = async (searchData, pageNo, token) => {
  try {
    const { data } = await axios.get(
      `${host}/allUsers?search=${searchData}&page=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const LoginApi = async (info) => {
  try {
    const { data } = await axios.post(`${host}/adminLogin`, info);
    return data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const deleteUser = async (id, token) => {
  try {
    await axios.delete(`${host}/user/${id}}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return error.response.data.message;
  }
};

export const editSpred = async (token, datas, type, metal) => {
  try {
    const { data } = await axios.post(
      `${host}/add${type}${metal}Spread`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addHighLowValues = async (token, datas, metal) => {
  try {
    const { data } = await axios.post(
      `${host}/add${metal}HighLowValues`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const allSpreads = async (id) => {
  try {
    const { data } = await axios.get(`${host}/getSpread/${id}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const blockUser = async () => {
  try {
    const { data } = await axios.put(`${host}/blockUser/:id`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const unBlockUser = async () => {
  try {
    const { data } = await axios.put(`${host}/unBlockUser/:id`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAdminProfile = async (id) => {
  try {
    const { data } = await axios.get(`${host}/getAdminProfile/${id}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const AdminEditProfile = async (token, datas, id) => {
  try {
    const { data } = await axios.put(`${host}/AdminEditProfile/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const commodityHideOrNot = async (id) => {
  try {
    const { data } = await axios.get(`${host}/commodityHideOrNot/${id}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const showAndHideSilver = async (id, status) => {
  try {
    const { data } = await axios.put(`${host}/showAndHideSilver/${id}`, {
      status: status,
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addNewTrader = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/register`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllPendingandExitOrders = async (
  type,
  filterType,
  sortByQty,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/adminrequestorders?type=${type}&filterType=${filterType}&sortByQty=${sortByQty}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const triggerOrder = async (id, status, token) => {
  try {
    const { data } = await axios.put(
      `${host}/triggerOrder/${id}`,
      { orderStatus: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllOpenOrders = async (
  type,
  filterType,
  query,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getallOpenOrders?type=${type}&filterType=${filterType}&search=${query}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllClosedOrders = async (
  type,
  filterType,
  sortByQty,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getallClosedOrders?type=${type}&filterType=${filterType}&sortByQty=${sortByQty}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllTransactionRequest = async (
  filterType,
  query,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllTransactionRequest?filterType=${filterType}&search=${query}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllDeposits = async (
  query,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllDeposits?search=${query}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllWithdraws = async (
  query,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllWithdraws?search=${query}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllRejects = async (
  filterType,
  query,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllRejects?filterType=${filterType}&search=${query}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const addFund = async (token, userId, docId, amount) => {
  try {
    const { data } = await axios.put(
      `${host}/addFund?userId=${userId}&docId=${docId}&amount=${amount}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const withdrawFund = async (token, userId, docId, amount) => {
  try {
    const { data } = await axios.put(
      `${host}/withdrawFund?userId=${userId}&docId=${docId}&amount=${amount}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const rejectRequest = async (token, userId, docId, type, amount) => {
  try {
    const { data } = await axios.put(
      `${host}/rejectRequest?userId=${userId}&docId=${docId}&type=${type}&amount=${amount}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const adminAddWithrawRequest = async (token, withrawData) => {
  try {
    const { data } = await axios.put(
      `${host}/adminAddWithrawRequest`,
      withrawData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const adminAddFundRequest = async (token, formData) => {
  try {
    const { data } = await axios.put(`${host}/adminAddFundRequest`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getCustomerStatementReport = async (
  startDate,
  endDate,
  transactionType,
  pageNumber,
  token,
  id
) => {
  try {
    const { data } = await axios.get(
      `${host}/getCustomerStatementReport/${id}?startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getCustomerTradeBookReport = async (
  userId,
  filterItem,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/userTradeBook/${userId}?startDate=${startDate}&endDate=${endDate}&filterItem=${filterItem}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getTradeBookReportInExcel = async (
  userId,
  filterItem,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getTradeBookReportInExcel/${userId}?startDate=${startDate}&endDate=${endDate}&filterItem=${filterItem}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getCustomerBankAccounuts = async (
  userId,
  searchQuery,
  pageNumber,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/userBankAccounuts/${userId}?&search=${searchQuery}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllInvoices = async (
  filterType,
  query,
  pageNumber,
  startDate,
  endDate,
  token
) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllInvoices?filterType=${filterType}&search=${query}&startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCustomerDocuments = async (userId, token) => {
  try {
    const { data } = await axios.get(`${host}/userDocuments/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const updateTraderdata = async (datas, userId, token) => {
  try {
    const { data } = await axios.put(
      `${host}/updateTraderData/${userId}`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const placeOrder = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/adminPlaceOrder`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const approveUserKyc = async (userId, token) => {
  try {
    const { data } = await axios.put(
      `${host}/approveKyc/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deletAdminBankDetails = async (docId, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteBankDetails/${docId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const adminAddUserBankDetails = async (datas, userId, token) => {
  try {
    const { data } = await axios.post(
      `${host}/adminAddUserBankDetails/${userId}`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const adminDeleteUserBankDetails = async (bankId, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/deleteUserBankDetails/${bankId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const adminSendMessage = async (message, token) => {
  try {
    const { data } = await axios.post(`${host}/adminSendMessage`, message, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllmessages = async (startDate, endDate, pageNumber, token) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllmessages?startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const upadteDate = async (messageId, token) => {
  try {
    const { data } = await axios.put(
      `${host}/upadteDate/${messageId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteMessage = async (messageId, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteMessage/${messageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const addCategory = async (category, token) => {
  try {
    const { data } = await axios.post(`${host}/addCategory`, category, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllCategory = async (token, searchData, pageNo) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllCategory?search=${searchData}&page=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const editCategory = async (datas, id, token) => {
  try {
    const { data } = await axios.put(`${host}/editCategory/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteCategory = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteCategory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const addProcuct = async (token, dataa) => {
  try {
    const { data } = await axios.post(`${host}/addProcuct`, dataa, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllProducts = async (searchData, pageNo, token) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllProducts?search=${searchData}&page=${pageNo}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const editProduct = async (datas, id, token) => {
  try {
    const { data } = await axios.put(`${host}/editProduct/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteProduct = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteProduct/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllOrders = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getAllOrders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const orderStatusChange = async (token, id) => {
  try {
    const { data } = await axios.put(
      `${host}/orderStatusChange/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const uploadImage = async (file, token) => {
  const formData = new FormData();
  formData.append("image", file);
  try {
    const { data } = await axios.post(`${host}/uploadImage`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllImages = async (userId) => {
  try {
    const { data } = await axios.get(`${host}/getAllImages/${userId}`);
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const deleteImage = async (id, image, token) => {
  try {
    const { data } = await axios.delete(
      `${host}/deleteImage/${id}?image=${image}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const adminCreateNews = async (datas, token) => {
  try {
    const { data } = await axios.post(`${host}/adminCreateNews`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllNews = async (startDate, endDate, pageNumber, id) => {
  try {
    const { data } = await axios.get(
      `${host}/allNewsesWithPagination/${id}?startDate=${startDate}&endDate=${endDate}&page=${pageNumber}`
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const activeNews = async (id, status, token) => {
  try {
    const { data } = await axios.put(
      `${host}/activeNews/${id}`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getIsNewsEnable = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/v1/admin/news-enable`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const newEnabling = async (status, token) => {
  try {
    const { data } = await axios.put(
      `${host}/api/v1/admin/news-enable`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteNews = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/deleteNews/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const blockAndUnBlockUser = async (id, status, token) => {
  try {
    const { data } = await axios.put(
      `${host}/blockAndUnBlockUser/${id}`,
      { status: status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getWalletAmount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getWalletAmount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getTradersCount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getTradersCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getPendingOredersCount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getPendingOredersCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllRequestsCount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getAllRequestsCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getChartCount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getChartCount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getBookingDeskData = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getBookingDeskData`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const findUser = async (email) => {
  try {
    const { data } = await axios.post(`${host}/findUser`, { email });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const sendResetPasswordCode = async (email) => {
  try {
    const { data } = await axios.post(`${host}/sendResetPasswordCode`, {
      email,
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const validateResetCode = async (email, code) => {
  try {
    const { data } = await axios.post(`${host}/validateResetCode`, {
      email,
      code,
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const changePassword = async (formData) => {
  try {
    const { data } = await axios.post(`${host}/changePassword`, {
      formData,
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const changePasswordByOldPassword = async (token, datas) => {
  try {
    const { data } = await axios.post(
      `${host}/changePasswordByOldPassword`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const changeTvPassword = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/changeTvPassword`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getCurrentlyPackage = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getCurrentlyPackage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const fetchLondonFixValue = async (token) => {
  try {
    const { data } = await axios.get(`${host}/fetchLondonFixValue`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
export const addLondonFixValue = async (value, token) => {
  try {
    const { data } = await axios.post(`${host}/addLondonFixValue`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
export const updateLondonFixValue = async (value, id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/updateLondonFixValue/${id}`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getShopCategories = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getShopCategories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getShopProduct = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getShopProduct`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const addShopBanner = async (token, value) => {
  try {
    const { data } = await axios.post(`${host}/addShopBanner`, value, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
export const deleteShopBanner = async (token, id) => {
  try {
    const { data } = await axios.delete(`${host}/deleteShopBanner/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getAllShopBanner = async (id) => {
  try {
    const { data } = await axios.get(`${host}/getAllShopBanner/${id}`);
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const editLiveValueTypeForDisplay = async (token, value) => {
  try {
    const { data } = await axios.put(
      `${host}/editLiveValueTypeForDisplay`,
      value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};

export const getLiveValueTypeForDisplay = async (token, id) => {
  try {
    const { data } = await axios.get(
      `${host}/getLiveValueTypeForDisplay/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
export const getRetailGoldPrice = async (token, id) => {
  try {
    const { data } = await axios.get(`${host}/getRetailGoldPrice`);
    return data;
  } catch (error) {
    return error.message.response.data.message;
  }
};
